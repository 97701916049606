import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { notification } from './messaging';
import { useFlagsmith } from 'flagsmith/react';
import {
  useGetFisheryAdminUserQuery,
  useGetUserQuery,
} from '../components/user_profile/userProfileSlice';
import { apiSlice } from '../store/apiSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const fullWidthAbsoluteElementIds = ['fixed-header'];

/**
 * Prevents background page (body) scroll. E.g. when a modal/drawer is open
 * @param controllerKey a unique key used to identify which elements are currently disabling the scroll
 * @param isScrollDisabled whether scroll is currently disabled
 */
export function usePreventScroll(controllerKey, isScrollDisabled) {
  const dataPropertyName = 'scrollController';

  const getControllerKeys = useCallback(
    () => JSON.parse(document.body.dataset?.[dataPropertyName] || null) || [],
    []
  );

  /**
   * Is the element corresponding to controllerKey currently locking scroll?
   */
  const isControllingScroll = useCallback(
    () => getControllerKeys()?.includes(controllerKey),
    [controllerKey, getControllerKeys]
  );

  /**
   * Register a scroll lock associated with controllerKey
   */
  const lockScroll = useCallback(() => {
    const scrollBarWidth = Math.max(
      window.innerWidth - document.body.clientWidth,
      0
    );
    document.body.style.overflow = 'hidden';
    fullWidthAbsoluteElementIds.forEach((id) => {
      if (document?.getElementById(id)) {
        document.getElementById(id).style.width =
          `calc(100vw - ${scrollBarWidth}px)`;
      }
    });
    document.body.style.width = `calc(100vw - ${scrollBarWidth}px)`;
    if (!isControllingScroll()) {
      document.body.dataset[dataPropertyName] = JSON.stringify(
        getControllerKeys().concat([controllerKey])
      );
    }
  }, [controllerKey, getControllerKeys, isControllingScroll]);

  /**
   * Unregister a scroll lock associated with controllerKey
   */
  const unlockScroll = useCallback(() => {
    if (isControllingScroll()) {
      document.body.dataset[dataPropertyName] = JSON.stringify(
        getControllerKeys().filter((key) => key !== controllerKey)
      );
    }
    if (!getControllerKeys()?.length) {
      // No controlling elements -- unset scroll
      document.body.style.removeProperty('overflow');
      document.body.style.width = `auto`;
      if (document?.getElementById('fixed-header')) {
        document.getElementById('fixed-header').style.width = `100%`;
      }
      fullWidthAbsoluteElementIds.forEach((id) => {
        if (document?.getElementById(id)) {
          document.getElementById(id).style.width = `100%`;
        }
      });
      // document.body.style.paddingRight = "0";
    }
  }, [controllerKey, getControllerKeys, isControllingScroll]);

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isScrollDisabled) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => document && unlockScroll();
  }, [isScrollDisabled, lockScroll, unlockScroll]);
}

export const useFormSubmitCheck = (
  form,
  required_fields,
  block_on_errors = false
) => {
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleFieldsChange = useCallback(() => {
    const allTouched =
      required_fields
        .map((fName) => form.isFieldTouched(fName) || form.getFieldValue(fName))
        .filter((touched) => touched).length >= required_fields.length;
    const anyErrors = block_on_errors
      ? form.getFieldsError().filter(({ errors }) => errors.length).length > 0
      : false;
    const enableSubmit = allTouched && !anyErrors;
    setDisableSubmit(!enableSubmit);
  }, [block_on_errors, form, required_fields]);

  return [handleFieldsChange, disableSubmit];
};

export const UserIdentificationProvider = ({ children }) => {
  useUserIdentification();
  return children;
};

export const useUserIdentification = (isAdminUser = false) => {
  const flagsmith = useFlagsmith();
  const { data: anglerInfo } = useGetUserQuery();
  const { data: fisheryAdminInfo } = useGetFisheryAdminUserQuery('', {
    skip: !isAdminUser,
  });

  // Update heap properties whenever angler details changes
  useEffect(() => {
    let user = fisheryAdminInfo ?? anglerInfo;
    if (!user) {
      return;
    }
    const is_fishery_admin = Boolean(fisheryAdminInfo || user?.fishery_id);
    user = is_fishery_admin ? fisheryAdminInfo : anglerInfo;
    const tracking_id = is_fishery_admin
      ? 'Fishery ' + user.fishery_id
      : user.email;

    if (flagsmith) {
      flagsmith.identify(tracking_id, {
        app_public_id: user?.public_id ?? user?.id,
        is_fishery_admin: is_fishery_admin,
        fishery_id: user?.fishery_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });
    }

    if (!document.cookie.match(new RegExp(`(^| )CookieConsent=([^;]+)`))?.[2]) {
      return;
    }
    if (window?.heap) {
      window.heap.identify(tracking_id);
      window.heap.addUserProperties({
        app_public_id: user?.public_id ?? user?.id,
        is_fishery_admin: is_fishery_admin,
        fishery_id: user?.fishery_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });
    }
  }, [flagsmith, anglerInfo, fisheryAdminInfo]);

  const resetIdentity = useCallback(() => {
    window?.heap?.resetIdentity();
    flagsmith?.logout();
  }, [flagsmith]);

  return { resetIdentity };
};

export function useLocalStorage(key, initialValue) {
  const [localState, setLocalState] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      if (value) {
        return value;
      } else {
        window.localStorage.setItem(key, JSON.stringify(initialValue));
        window.dispatchEvent(new Event('storage'));
        return JSON.stringify(initialValue);
      }
    } catch (error) {
      window.localStorage.setItem(key, JSON.stringify(initialValue));
      window.dispatchEvent(new Event('storage'));
      return JSON.stringify(initialValue);
    }
  });

  const setState = useCallback(
    (v) => {
      try {
        const nextState =
          typeof v === 'function' ? v(JSON.parse(localState)) : v;

        if (nextState === undefined || nextState === null) {
          window.localStorage.removeItem(key);
          setLocalState(null);
        } else {
          window.localStorage.setItem(key, JSON.stringify(nextState));
          setLocalState(JSON.stringify(nextState));
          window.dispatchEvent(new Event('storage'));
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, localState]
  );

  useEffect(() => {
    if (
      window.localStorage.getItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      window.localStorage.setItem(key, JSON.stringify(initialValue));
      setLocalState(JSON.stringify(initialValue));
      window.dispatchEvent(new Event('storage'));
    }
  }, [key, initialValue]);

  useEffect(() => {
    // Triggers an update in the calling component if a different component updates the state value
    const updateLocalState = () => {
      setLocalState(window.localStorage.getItem(key));
    };
    window.addEventListener('storage', updateLocalState);

    return () => window.removeEventListener('storage', updateLocalState);
  }, [key]);

  return [localState ? JSON.parse(localState) : initialValue, setState];
}

export const useLogin = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const instance = useMemo(
    () =>
      axios.create({
        withCredentials: true,
        crossDomain: true,
        baseURL: `${process.env.REACT_APP_AXIOS_URL}`,
        'Access-Control-Allow-Origin': '*',
        sameSite: null,
        secure: false,
      }),
    []
  );

  const onLogin = ({ username = null, password = null }) => {
    if (!username || !password) {
      notification.error({
        description: 'Please provide your email and password',
      });
    }

    const formData = new FormData();
    formData.append('username', username.trim());
    formData.append('password', password);

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setLoading(true);
    return instance
      .post('login', formData, config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.result;
          notification.success({
            description: data,
          });
        } else {
          notification.error({
            description: 'Incorrect Details. Please try again.',
          });
          return Promise.reject({ response: res });
        }
      })
      .catch((err) => {
        notification.error({
          description: 'Incorrect Details. Please try again.',
        });
        if (err.response) {
          if (err.response.status !== 401 && err.response.status !== 200) {
            notification.error({
              description: 'Incorrect Details. Please try again.',
            });
          }
        }
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(apiSlice.util.invalidateTags(['FisheryUser', 'User']));
        setLoading(false);
      });
  };

  const refreshToken = useCallback(() => {
    instance
      .get('refresh')
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  }, [instance]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return [onLogin, loading];
};

export const useResetPassword = (verificationId, mode = 'user_reset') => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [validating, setValidating] = useState(Boolean(verificationId));
  const [submitting, setSubmitting] = useState(false);

  const resetPassword = useCallback(
    (newPassword) => {
      if (!newPassword) {
        notification.error({
          description: 'Please provide your new password',
        });
      }
      setSubmitting(true);
      const payload_data = {
        token: verificationId,
        mode: mode,
        password: newPassword,
      };
      const axios_config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      return axios
        .post('fishery/auth/reset', payload_data, axios_config)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.result;
            notification.success({
              description: data,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status !== 401 && err.response.status !== 200) {
              notification.error({
                description: err.response.data.detail,
              });
            }
          }
          return Promise.reject(err);
        })
        .finally(() => setSubmitting(false));
    },
    [mode, verificationId]
  );

  const validateLink = useCallback((verificationId, mode) => {
    if (!verificationId || !mode) {
      setIsTokenValid(false);
      return;
    }
    setValidating(true);
    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        token: verificationId,
        mode: mode,
      },
    };

    axios
      .get('fishery/auth/verify', axios_config)
      .then((res) => {
        if (res.status === 200) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      })
      .catch(() => {
        setIsTokenValid(false);
      })
      .finally(() => setValidating(false));
  }, []);

  useEffect(() => {
    validateLink(verificationId, mode);
  }, [mode, validateLink, verificationId]);

  return [
    isTokenValid,
    resetPassword,
    { isValidating: validating, isSubmitting: submitting },
  ];
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useCheckAdminInWebview = () => {
  const isWebview = useQuery().get('isWebview');
  const [inWebview, setInWebview] = useLocalStorage('adminIsInWebview', false);
  useEffect(() => {
    if (isWebview && !inWebview) {
      setInWebview(true);
    }
  }, [inWebview, isWebview, setInWebview]);
};

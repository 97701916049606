import { Button, notification, Space } from 'antd';
export var ordinal_suffix_of = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const EXOTIC_FISHERY = process.env?.REACT_APP_FISHERY_EXOTIC_ID;

export { EXOTIC_FISHERY as exotic_fishery };

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const lakeAvailabilityColors = {
  no: 'grey',
  min: 'green',
  medium: 'yellow',
  full: 'red',
};

export function validateType(file) {
  return file.type === 'image/jpeg' || file.type === 'image/png';
}

export function validateSize(file) {
  const isGt2M = file.size / 1024 / 1024 > 10;
  return !isGt2M;

  // const isLt2M = (file.size / 1024 / 1024) < 2;
  // if (!isLt2M) {
  //   return false
  // }
  // return true
}

export const pretty_print_list_str = (item, _str = '') => {
  for (var i = 0; i < item.length; i++) {
    if (i === 0) {
      _str = '';
    } else if (i === 1) {
      _str.concat(', '.concat(item.name));
    } else if (i === item.length - 1) {
      _str.concat(' & '.concat(item.name));
    }
  }
};
export const openNotification = (type) => {
  const [api, contextHolder] = notification.useNotification();
  const notificationWithData = () => {
    api[type]({
      message: 'Notification Title',
      description:
        'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
    });
  };
  notificationWithData();
  return contextHolder;
};

import React from 'react';
import { Col, Drawer, Row } from 'antd';
import { BackButtonLayout } from '../../../../../containers/BackButtonLayout';

import fish from '../../sliders/assets/image/fish.png';
import trophy from '../../sliders/assets/image/trophy.svg';
import noImage from '../../../../common/assets/images/no-photo.jpg';
import equality from '../../sliders/assets/image/equality.svg';
import user from '../../sliders/assets/image/circle-user.svg';
import water from '../../sliders/assets/image/water.svg';
import bait from '../../sliders/assets/image/fishing-rod.svg';
import rig from '../../sliders/assets/image/bait.svg';
import clock from '../../sliders/assets/image/calendar-clock.svg';

import { ImageSlider } from '../index';

import moment from 'moment';

const CatchReportsDrawer = ({ drawerOpen, setIsDrawerOpen, catchDetails }) => (
  <Drawer
    title={null}
    footer={null}
    closeable={false}
    maskCloseable={false}
    className='fishery_profile__drawer'
    open={drawerOpen}
    onClose={() => setIsDrawerOpen(false)}
  >
    <BackButtonLayout
      useCrossIcon
      overlapHeader={true}
      onBack={() => setIsDrawerOpen(false)}
    >
      {catchDetails?.personal_best && (
        <div
          className='brFull bg-white sb-blue fs-17 gBold row-center'
          style={{
            position: 'absolute',
            top: '35px',
            right: '7%',
            zIndex: 2,
            padding: '5px 15px',
          }}
        >
          <img
            height={21}
            src={trophy}
            className='mg-right-5'
            alt='personal-best'
          ></img>
          PB
        </div>
      )}
      <ImageSlider
        height={'24rem'}
        images={
          catchDetails?.image_upload
            ? [{ src: catchDetails.image_upload, alt: 'catch-image' }]
            : [{ src: noImage, alt: 'catch-image' }]
        }
        openOnClick
      />
      <div className='main_container_padding'>
        <div>
          <Row className='mg-top-20 gSemiBold pd-left-30 pd-right-30'>
            <Col span={24} className='mg-bottom-20'>
              <Row justify={'space-between'}>
                <Col className='gSemiBold fs-28 '>Full Catch Report</Col>
                <Col className='gSemiBold fs-18 fr row-center'>
                  ID:{' '}
                  <span className='gLight mg-left-5'>
                    {' '}
                    {catchDetails?.public_id}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24} className='mg-bottom-30'>
              <Row
                justify='space-between'
                gutter={[0, 10]}
                className='br10  white row-center pd-10  center fs-18 gSemiBold'
                style={{ background: '#2772af' }}
              >
                <div className='row-center mg-left-10'>
                  <div style={{ width: 32 }} className='mg-right-10'>
                    <img
                      width={32}
                      src={fish}
                      className='mg-right-10'
                      alt='fish'
                    ></img>
                  </div>

                  <div>
                    Species:{' '}
                    <span className='mg-left-5 gLight'>
                      {catchDetails?.fish_species}
                    </span>
                  </div>
                </div>
                <div className='row-center mg-left-10 mg-right-10'>
                  <div style={{ width: 32 }} className='mg-right-10 center'>
                    <img width={27} src={equality} alt='equality'></img>
                  </div>
                  Weight:{' '}
                  <span className='mg-left-5 gLight'>{`${catchDetails?.fish_weight_lb}lb ${catchDetails?.fish_weight_oz}oz`}</span>{' '}
                </div>
              </Row>
            </Col>
            <Col>
              <div className='row-center mg-bottom-20'>
                <img width={28} src={user} className='mg-right-10' alt='user' />
                <div className='gSemiBold fs-18'>
                  Angler Name:{' '}
                  <span className='mg-left-5 gLight'>{catchDetails?.name}</span>
                </div>
              </div>
              <div className='row-center mg-bottom-20'>
                <img
                  width={28}
                  src={clock}
                  className='mg-right-10'
                  alt='clock'
                />
                <div className='gSemiBold fs-18'>
                  Date & Time:{' '}
                  <span className='mg-left-5 gLight'>
                    {moment(catchDetails?.fish_caught_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </span>
                </div>
              </div>
              <div className='row-center mg-bottom-20'>
                <img
                  width={28}
                  src={water}
                  className='mg-right-10'
                  alt='water'
                />
                <div className='gSemiBold fs-18'>
                  Lake:{' '}
                  <span className='mg-left-5 gLight'>{catchDetails?.lake}</span>
                </div>
              </div>
              {catchDetails?.rig && (
                <div className='row-center mg-bottom-20'>
                  <img width={28} src={rig} className='mg-right-10' alt='rig' />
                  <div className='gSemiBold fs-18'>
                    Rig Used:{' '}
                    <span className='mg-left-5 gLight'>
                      {catchDetails?.rig}
                    </span>
                  </div>
                </div>
              )}
              {catchDetails?.bait && (
                <div className='row-center'>
                  <img
                    width={28}
                    src={bait}
                    className='mg-right-10'
                    alt='bait'
                  />
                  <div className='gSemiBold fs-18'>
                    Bait Used:{' '}
                    <span className='mg-left-5 gLight'>
                      {catchDetails?.bait}
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </BackButtonLayout>
  </Drawer>
);

export default CatchReportsDrawer;

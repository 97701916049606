import React, { useState } from 'react';
import { Layout } from 'antd';
import talkSport from '../assets/images/Icons/talkSPORT_320.png';
import SBFooter from '../components/common/Footer';
import SBHeader from '../components/common/Header';

const { Content } = Layout;

function HomeLayout(props) {
  const [layoutState, setLayoutState] = useState({
    visible: false,
    bodyOverlay: 'hide',
    headerExpand: 'small',
    menuVisible: false,
    isLoggedIn: false,
    loading: false,
  });

  const getLayoutStatePropertySetter = (propName) => (val) =>
    setLayoutState((prevState) => ({
      ...prevState,
      [propName]: typeof val === 'function' ? val(prevState?.[propName]) : val,
    }));

  const closeOverlay = () => {
    setLayoutState((prevState) => ({
      ...prevState,
      bodyOverlay: 'hide',
      headerExpand: 'small',
    }));
  };

  const isUserProfile = props.isUserProfile;

  return (
    <div id='home-top-menu'>
      <Layout className='layout _header_main_content'>
        <SBHeader
          headerExpandClass={layoutState?.headerExpand}
          useLogo={false}
          usesFixedMobileView={true}
          isLoggedInState={[
            layoutState?.isLoggedIn,
            getLayoutStatePropertySetter('isLoggedIn'),
          ]}
          loadingState={[
            layoutState?.loading,
            getLayoutStatePropertySetter('loading'),
          ]}
          triggerRedirect={() => {}}
          useMaxWidth
          isHome
        />
        <Content>
          <div
            id='mainbody-overlay'
            className={layoutState.bodyOverlay}
            onClick={closeOverlay}
          />
          <div className='main-container home-page-layout'>
            {props.children}
          </div>
        </Content>
      </Layout>
      <SBFooter />
    </div>
  );
}

export default HomeLayout;

import { Row, Button } from 'antd';
import SearchBox from '../../common/SearchBox';
import React from 'react';
import '../assets/css/masthead.css';
import user from '../assets/images/user-icon.svg';
import TrustPilot from '../assets/images/trust-pilot.svg';
import backgroundImage1 from '../assets/images/Slide-1.jpg';
import backgroundImage2 from '../assets/images/Slide-2.jpg';
import backgroundImage3 from '../assets/images/Slide-3.jpg';
import backgroundImage4 from '../assets/images/Slide-4.jpg';
import backgroundImage5 from '../assets/images/Slide-5.jpg';
import backgroundImage6 from '../assets/images/Slide-6.jpg';
import roundedLogo from '../../../assets/images/img_blue_fish.png';

import { useHistory } from 'react-router';

function Masthead() {
  const history = useHistory();
  const callToActionButtonStyle =
    'gBold br20 fs-15 white txt-center uppercase w-full pd-12 mg-bottom-0';

  const video =
    'https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/videos/blue-and-grey-premium-luxurlky-geometric-triangles-2022-11-08-01-56-18-utc.mp4';
  const videoWebM =
    'https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/videos/blue-and-grey-premium-luxurlky-geometric-triangles-2022-11-08-01-56-18-utc.webm';

  return (
    <div className={'masthead'}>
      {/*Images shown from bottom up */}
      <img
        src={backgroundImage6}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage5}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage4}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage3}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage2}
        alt={'View of a lake and sky'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage1}
        alt={'Aerial view of a lake'}
        className='masthead__background'
      />
      <div className='masthead-swimbooker-mobile-logo'>
        <img className='mg-right-15' width={40} src={roundedLogo} />
        <div className='gSemiBold sb-blue fs-20 white row-center'>
          swimbooker
        </div>
      </div>
      <div className={'masthead__content main_container_padding'}>
        <Row className='w-fit'>
          <h1 className='masthead__title  gBold white mg-bottom-0'>
            Less Hassle. More Fishing.
          </h1>
        </Row>
        <Row>
          <p className='fs-24 gMedium w-full sb-blue-100 txt-center '>
            Book your next session with over 250+ lakes to choose from!
          </p>
          <Row className='masthead__buttons search-box'>
            <SearchBox className={'mg-bottom-10'} tall active={false} />
          </Row>
        </Row>
        <div className='buttons-container justify-center'>
          <div className=''>
            <Button
              className='create-account-button sb-blue-bg-600 gBold fs-18'
              onClick={() => {
                history.push('user/signup');
              }}
            >
              <img src={user} alt='user' className='mg-right-10' />
              Create Free Account
            </Button>
          </div>
          <a className='browse-lake gBold cp' href='/search'>
            Browse all lakes
          </a>
        </div>
      </div>
      <div className='trust-pilot cp'>
        <a href={'https://uk.trustpilot.com/review/www.swimbooker.com'}>
          <img src={TrustPilot} alt='trust-pilot' />
        </a>
      </div>
    </div>
  );
}

export default Masthead;

// STORE CONSTANTS

export const USER = {
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
};

export const MENU = {
  TOGGLE_MENU: 'TOGGLE_MENU',
  TOGGLE_CATCH_REPORT: 'TOGGLE_CATCH_REPORT',
  UPDATE_MENU_REF: 'UPDATE_MENU_REF',
};

export const LAKE_MANAGER = {
  UPDATE_SERVICE_TYPE: 'UPDATE_SERVICE_TYPE',
};

export const ADMIN_LOGOUT_ACTION = 'admin/log_out';

export const PRIVACY_POLICY_URL =
  process.env?.REACT_APP_PRIVACY_URL ??
  'https://docs.google.com/viewerng/viewer?url=https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/swimbooker_privacy_policy.pdf';
export const TERMS_AND_CONDITIONS_URL =
  process.env?.REACT_APP_TERMS_URL ??
  'https://docs.google.com/viewerng/viewer?url=https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/swimbooker_terms__conditions.pdf';

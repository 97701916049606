import { Button, Layout } from 'antd';
import SBFooter from '../components/common/Footer';
import {
  CloseOutlined,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';
import SBHeader from '../components/common/Header';
import { useState } from 'react';
import LeftChevron from '../assets/images/LeftChevron';

const { Header, Content } = Layout;

export function BackButtonLayout({
  children,
  className = '',
  onBack = undefined,
  showFavouriteButton = false,
  onFavourite = undefined,
  isFavourite = false,
  showShareButton = false,
  onShare = undefined,
  showFooter = false,
  overlapHeader = false,
  useCrossIcon = false,
}) {
  let history = useHistory();

  return (
    <Layout className={'profile_layout ' + className}>
      <Header
        className={
          'profile_layout__header main_container_padding ' +
          (overlapHeader ? 'profile_layout__header--overlap-header' : '')
        }
      >
        <Button
          className={'profile_layout__header__back_btn'}
          shape='circle'
          icon={
            useCrossIcon ? (
              <CloseOutlined />
            ) : (
              <LeftChevron style={{ fontSize: '16px' }} />
            )
          }
          size={'large'}
          onClick={() => (onBack ? onBack() : history.goBack())}
        />
        <div className={'profile_layout__header__buttons'}>
          {showShareButton && onShare && (
            <Button
              shape='circle'
              icon={<ShareAltOutlined />}
              size='large'
              onClick={onShare}
            />
          )}
          {showFavouriteButton && onFavourite && (
            <Button
              shape='circle'
              icon={
                isFavourite ? (
                  <HeartFilled className='sb-color-red' />
                ) : (
                  <HeartOutlined />
                )
              }
              size='large'
              onClick={onFavourite}
            />
          )}
        </div>
      </Header>
      <Content
        className={
          'profile_layout__content ' +
          (overlapHeader ? 'profile_layout__content--overlap-header' : '')
        }
      >
        {children}
      </Content>
      {showFooter && <SBFooter />}
    </Layout>
  );
}

export function MainPageBackButtonLayout({
  overlapHeader = false,
  onBack = undefined,
  showFavouriteButton = false,
  onFavourite = undefined,
  isFavourite = false,
  showShareButton = false,
  onShare = undefined,
  children,
}) {
  const isLoggedInState = useState(false);
  const loadingState = useState(false);

  return (
    <>
      <SBHeader
        useLogo
        hideOnMobile
        isLoggedInState={isLoggedInState}
        loadingState={loadingState}
        triggerRedirect={() => {}}
        useMaxWidth
      />
      <BackButtonLayout
        showFooter
        className={'_header_main_content'}
        overlapHeader={overlapHeader}
        onBack={onBack}
        showFavouriteButton={showFavouriteButton}
        onFavourite={onFavourite}
        showShareButton={showShareButton}
        onShare={onShare}
        isFavourite={isFavourite}
      >
        {children}
      </BackButtonLayout>
    </>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import './assets/css/home.css';
import HomePageArticleContent from './components/HomePageArticleContent';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Masthead from './components/Masthead';
import HomeQuickLinks from './components/HomeQuickLinks';
import { useLottie, useLottieInteractivity } from 'lottie-react';
import walletAnimation from './assets/animations/custom-wallet.json';
import calendarAnimation from './assets/animations/custom-calendar.json';
import supportTicketAnimation from './assets/animations/110889-support-ticket-confirmation.json';
import {
  CatchReportSlider,
  FisheriesSlider,
} from '../common/components/sliders';
import { useGetAllCatcheReportsQuery } from '../user_profile/querySlice/anglerCatchesSlice';
import { useGetSearchResultsQuery } from '../search/searchSlice';
import HomePageVenueContent from './components/HomePageVenueContent';
import { Link } from 'react-router-dom';
const imagePaths = {
  listLakeBg: require('./assets/images/List-Lake-bg.svg').default,
  errorIllustration: require('../search/assets/images/no-content.svg').default,
  infoicon1: require('./assets/images/info-icon1.svg').default,
  infoicon2: require('./assets/images/info-icon2.svg').default,
  infoicon3: require('./assets/images/info-icon3.svg').default,
  infoicon4: require('./assets/images/info-icon4.svg').default,
  checked: require('./assets/images/checked.svg').default,
  iphone: require('./assets/images/AJ-Final.png'),
};

// Prefetch the images
Object.values(imagePaths).forEach((src) => {
  const img = new Image();
  img.src = src;
});

function InfoBlock({ icon, title, children }) {
  return (
    <div className={'home_info_block mg-bottom-20'}>
      <span
        className={'home_info_block__icon sb-blue row-center fs-25 pd-bottom-5'}
      >
        <img src={icon} alt='info-icons' />
      </span>
      <h4 className='home_info_block__title txt-center fs-22 gBold mg-bottom-5'>
        {title}
      </h4>
      <p className='gSemiBold grey-800 fs-18 txt-center'>{children}</p>
    </div>
  );
}

function useInfoBlockAnimation({ lottieSrc, className = undefined }) {
  const lottieObj = useLottie({
    animationData: lottieSrc,
    loop: false,
    autoPlay: false,
    ...(className
      ? {
          className,
        }
      : {}),
  });
  return useLottieInteractivity({
    lottieObj: lottieObj,
    mode: 'scroll',
    actions: [
      {
        visibility: [0.3, 0.9],
        type: 'play',
        frames: [0, 30],
      },
    ],
  });
}

export default function Home() {
  const [articlesData, setArticlesData] = useState(null);
  const { data: catches, isFetching: isCatchesLoading } =
    useGetAllCatcheReportsQuery();

  const {
    data: featuredFisheries,
    isFetching: loadingFeaturedFisheries,
    isError: errorLoadingFeaturedFisheries,
  } = useGetSearchResultsQuery({
    term: null,
    include_bookable: true,
    include_directory: false,
  });
  const variantLeft = {
    visible: {
      x: 0,
      transition: {
        type: 'spring',
        bounce: 0.3,
        duration: 0.8,
      },
    },
    hidden: { x: '-100vw' },
  };
  const variantRight = {
    visible: {
      x: 0,
      transition: {
        type: 'ease',
        bounce: 0.3,
        duration: 0.8,
      },
    },
    hidden: { x: '100vw' },
  };
  const variantBottom = {
    visible: {
      y: 0,
      transition: {
        type: 'ease',
        bounce: 0.3,
        duration: 0.4,
      },
    },
    hidden: { y: '40vh' },
  };
  const getArticles = useCallback(() => {
    axios
      .get('social')
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.result;
          setArticlesData(result);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getArticles();
  }, [getArticles]);

  const AnimatedWallet = useInfoBlockAnimation({
    lottieSrc: walletAnimation,
    className: 'lottie-size-1_7',
  });
  const AnimatedCalendar = useInfoBlockAnimation({
    lottieSrc: calendarAnimation,
  });
  const AnimatedTicket = useInfoBlockAnimation({
    lottieSrc: supportTicketAnimation,
    className: 'lottie-size-4_5',
  });

  const featuredFisheriesErrorState = (
    <div className='grid-center h-full txt-center pd-10'>
      <img
        src={imagePaths.errorIllustration}
        alt='No results'
        className='w-130'
      />
      <h2 className='sb-blue-900 gBold fs-20'>There was an issue...</h2>
      <Button
        className='sb-blue-500 hover:sb-blue-700 grey-border-200 hover:grey-border-300 br8 fs-16 h-fit gBold pd-top-10 pd-bottom-10 pd-left-20 pd-right-20'
        ghost
        onClick={() => window.location.reload()}
      >
        Refresh Page
      </Button>
    </div>
  );

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='swimbooker provides anglers the best places to fish nearby including some of the best carp fishing lakes in the country. Find fisheries near you via swimbooker'
        />
        <title>Book Fishing Lakes & Day Tickets Near You | swimbooker</title>
      </Helmet>

      <Masthead />

      <Row className={'home_info_blocks_container main_container_padding'}>
        <Col span={24} className={'main_container_padding__content'}>
          <h2 className='sb-blue-900 gBold fs-3em txt-center mg-bottom-50'>
            Why swimbooker?
          </h2>
          <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.1 }}
          >
            <Row
              gutter={16}
              className='justify-between info-box'
              style={{ overflow: 'hidden' }}
            >
              <Col xs={24} sm={12} lg={6} className=''>
                <motion.div variants={variantBottom}>
                  <InfoBlock icon={imagePaths.infoicon1} title='Free to book.'>
                    Our platform is free to use with no additional fees to book.
                  </InfoBlock>
                </motion.div>
              </Col>
              <Col xs={24} sm={12} lg={6} className=''>
                <motion.div variants={variantBottom}>
                  <InfoBlock
                    icon={imagePaths.infoicon2}
                    title='Massive selection.'
                  >
                    Easily find the perfect venue that completely suits your
                    requirements.
                  </InfoBlock>
                </motion.div>
              </Col>
              <Col xs={24} sm={12} lg={6} className=''>
                <motion.div variants={variantBottom}>
                  <InfoBlock
                    icon={imagePaths.infoicon3}
                    title='Track your angling.'
                  >
                    Upload catches, favourite lakes & earn badges to progress.
                  </InfoBlock>
                </motion.div>
              </Col>
              <Col xs={24} sm={12} lg={6} className=''>
                <motion.div variants={variantBottom}>
                  <InfoBlock
                    icon={imagePaths.infoicon4}
                    title='We’re here to help.'
                  >
                    Our incredible team is here to support in any capacity that
                    is required.
                  </InfoBlock>
                </motion.div>
              </Col>
            </Row>
          </motion.div>
        </Col>
      </Row>

      <div
        id='home-page-featured-fisheries'
        className={'main_container_padding'}
      >
        <Row className='main_container_padding__content'>
          <Col span={24} className='mg-left-20 fishery-slider-contiainer'>
            <h2 className=' sb-blue-900 fs-38 gSemiBold mg-top-20 mg-bottom-0'>
              Book over 250+ lakes
            </h2>
            <p className='grey-700 gMedium fs-20'>
              An ever growing collection of some of the finest venues in the UK
              & abroad.
            </p>
          </Col>
        </Row>
        <Row className='main_container_padding__content'>
          <Col span={24}>
            {errorLoadingFeaturedFisheries ? (
              featuredFisheriesErrorState
            ) : (
              <FisheriesSlider
                fisheriesData={(featuredFisheries ?? []).map((fishery) => ({
                  city: fishery?.city,
                  id: fishery?.public_id,
                  image: fishery?.images?.[0],
                  name: fishery?.name,
                  postcode: fishery?.postcode,
                  rating: fishery?.rating,
                  reviews: fishery?.total_reviews,
                }))}
                loading={loadingFeaturedFisheries}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className=' sb-pro-ad-container sb-blue-bg-800'>
        <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.25 }}
        >
          <Row className='main_container_padding__content'>
            <Col span={0} xl={12}>
              <motion.div variants={variantLeft}>
                <img
                  src={imagePaths.iphone}
                  alt='iphone'
                  className='iphone-sb-image'
                />
              </motion.div>
            </Col>
            <Col span={24} xl={12}>
              <div className='fs-26 font-montserrat white txt-center '>
                <span style={{ fontWeight: 700 }}>
                  Level Up Your Angling -{' '}
                </span>
                Join swimbooker+
              </div>
              <Row
                gutter={[10, 0]}
                className='fs-18 sb-blue-100 gMedium mg-20 sb-bullets mg-bottom-40 justify-end'
              >
                <Col span={24} md={10}>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    Angling Insurance Cover
                  </div>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    Weekly Giveaway
                  </div>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    Cashback On Fishing
                  </div>
                </Col>
                <Col span={24} md={12}>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    Exclusive Perks & Discounts
                  </div>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    InSession Digital Tool
                  </div>
                  <div className='mg-bottom-10'>
                    <img
                      src={imagePaths.checked}
                      alt='checked-mark'
                      className='mg-right-10'
                    />
                    Advanced Catch Report Data
                  </div>
                </Col>
              </Row>
              <div className='row-center gap-2r w-full justify-center'>
                <a
                  href={`${process.env.REACT_APP_SBPLUS_URL}/sign-up`}
                  className='learn-more-button '
                >
                  Learn More
                </a>
                <a
                  href={`${process.env.REACT_APP_SBPLUS_URL}/sign-up#plans`}
                  className='hover:sb-blue-300 sb-blue-100 fs-18 gBold txt-underline'
                >
                  View Pricing
                </a>
              </div>
            </Col>
          </Row>
        </motion.div>
      </div>
      <Row>
        <Col span={24} className='main_container_padding'>
          <div className='main_container_padding__content'>
            <HomePageVenueContent />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          className='main_container_padding'
          id='home-page-article'
        >
          <div className={'main_container_padding__content'}>
            <HomePageArticleContent
              featured={articlesData?.featured}
              articles={articlesData?.articles?.slice(1, 5)}
              loading={!articlesData}
            />
          </div>
        </Col>
      </Row>
      <div className='main_container_padding'>
        <Row className='main_container_padding__content'>
          <Col span={24}>
            <h2 className=' sb-blue-800 fs-30 gSemiBold mg-top-20 mg-bottom-0 row-center '>
              Live Catch Reports
              <span
                style={{ background: '#CC3D3D', width: 10, height: 10 }}
                className='brFull mg-left-10'
              ></span>
            </h2>
            <p className='gRegular grey-700 fs-20 mg-bottom-25'>
              swimbooker users can upload and manage their catch reports via
              their <span className='gSemiBold grey-900'>MySB</span> profile.
              Click for full catch report details.
            </p>
          </Col>
        </Row>
        <Row className='main_container_padding__content'>
          <Col span={24}>
            <CatchReportSlider
              isHomePage
              lakesData={catches?.catch_reports}
              loading={!catches}
              showFishery
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24} className='main_container_padding'>
          <div className='main_container_padding__content'>
            <div className='list-lake-container'>
              <div className='list-lake-text'>
                {' '}
                <div
                  style={{ lineHeight: 'normal', maxWidth: 400 }}
                  className='mg-bottom-15'
                >
                  List your lakes with us to save time and increase revenue
                </div>
                <Link
                  to='/swimbooker-fishery-manager'
                  className='list-lake-button sb-blue-bg-600'
                >
                  {' '}
                  Learn How
                </Link>
              </div>
              <img src={imagePaths.listLakeBg} className='list-lake-bg-img' />
            </div>
          </div>
        </Col>
      </Row>

      <div className='mg-top-20 mg-bottom-40 quick-links-container-wrapper main_container_padding'>
        <div className={'main_container_padding__content w-full'}>
          <Row
            className={'quick-links-container'}
            gutter={{ md: 16, lg: 32, xl: 64 }}
          >
            <Col span={24}>
              <h2 className='fs-36 sb-blue-900 gSemiBold mg-bottom-20'>
                Quick Access Links
              </h2>
            </Col>
            <Col xs={24} md={12} className='mg-bottom-40'>
              <HomeQuickLinks
                title={'For Anglers'}
                links={[
                  {
                    title: 'Browse All Fisheries',
                    link: '/search',
                  },
                  { title: 'The Angling Social', link: '/articles' },
                  { title: 'Create a Free Account', link: '/user/signup' },
                  {
                    title: 'Rate us on Trustpilot',
                    link: 'https://uk.trustpilot.com/review/www.swimbooker.com',
                  },
                ]}
              />
            </Col>
            <Col xs={24} md={12}>
              <HomeQuickLinks
                title={'For Fisheries'}
                links={[
                  { title: 'Fishery Management Login', link: '/admin/login' },
                  { title: 'Contact Form - Fishery', link: '/contact/fishery' },
                  { title: 'Claim Fishery Profile', link: '/contact/fishery' },
                  {
                    title: 'Why Swimbooker For My Fishery?',
                    link: '/swimbooker-fishery-manager',
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
